<template>
  <div>
    <iframe width="100%" height="1000px"
      src="https://lookerstudio.google.com/embed/reporting/37f26411-c193-4a2f-a688-a666c4bc7e6b/page/p_w2acjev33c"
      frameborder="0" style="border:0" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
}
</script>
